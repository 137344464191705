import {
  registerApplication,RegisterApplicationConfig
} from "single-spa";

export class ApplicationService {
  public init(env: any) {
  }
  private static _instance: ApplicationService;

  public static getInstance() {
    if (!this._instance) {
      this._instance = new ApplicationService();
    }
    return this._instance;
  }

  private constructor() {}

  public register(config: RegisterApplicationConfig) {
    console.log("Register app", config.name);
    return registerApplication(config);
  }
}
