import axios from "axios";
import { authSession$ } from "./IdentityService";

export const httpInstance = axios.create({});

httpInstance.interceptors.request.use((config) => {
  const token = authSession$.getValue().idToken;
  if (config) {
    if (typeof config.headers!["Authorization"] === "undefined") {
      config.headers!["Authorization"] = `Bearer ${token}`;
    }
  }

  return config;
});

