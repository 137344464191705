import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

interface Route {
  name: string;
  path: string;
  active: boolean;
  parent?: Route;
  subRoutes?: Route[];
}

export class RouterService {
  private static _instance: RouterService;

  public readonly routes$: BehaviorSubject<Route[]>;

  private prefix: string;
  private routes: Route[];

  public static getInstance() {
    if (!this._instance) {
      this._instance = new RouterService();
    }
    return this._instance;
  }

  private constructor() {
    this.prefix = "";
    this.routes$ = new BehaviorSubject<Route[]>([]);
  }

  public setPrefix(prefix) {
    this.prefix = prefix;
  }

  public setRoutes(routes) {
    console.log("RAW Routes:", routes);
    this.routes$.next(
      routes
        .filter((r) => r.path && r.type === "route")
        .map((r) => {
          return {
            path: r.path || "/",
            name: r.path || "", // TODO: Run this under translation
            active: false,
          };
        })
    );
  }
}
