import { Auth } from "@aws-amplify/auth";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { map } from "rxjs/operators";

interface User {
  familyName: string;
  givenName: string;
  email: string;
}

export class Session {
  readonly user: User;
  readonly error: any;
  readonly session: any;
  readonly idToken: string;

  constructor(sessionInfo: any, error?: any) {
    this.user = null;
    this.error = error;
    this.session = sessionInfo;

    this.idToken = sessionInfo?.signInUserSession?.idToken?.jwtToken;

    if (sessionInfo) {
      const { given_name, family_name, email } =
        sessionInfo?.signInUserSession?.idToken?.payload || {};
      this.user = {
        familyName: family_name,
        givenName: given_name,
        email,
      };
    }
  }
}

export const authSession$ = new BehaviorSubject<Session>(new Session(null));
export const isAuthenticated$ = authSession$.pipe(
  map((session: Session) => {
    return !!session?.user;
  })
);

export class IdentityService {
  private static _instance: IdentityService;
  private _initialized: Boolean = false;

  public static getInstance() {
    if (!this._instance) {
      this._instance = new IdentityService();
    }
    return this._instance;
  }

  constructor() {}

  public init({ sessionKey = "stk", amplify }): void {
    if (this._initialized) return;
    Auth.configure(amplify);
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((sessionInfo) => authSession$.next(new Session(sessionInfo)))
      .catch((err) => authSession$.next(new Session(null, err)));
    this._initialized = true;
  }

  async signIn(): Promise<any> {
    try {
      const response = await Auth.federatedSignIn({
        customProvider: "COGNITO",
      });
      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCurrentUser(): Promise<any> {
    const reply = await Auth.currentAuthenticatedUser()
      .then((reply) => reply.signInUserSession?.idToken?.payload)
      .catch((e) => {
        console.log(e);
        return null;
      });
    return reply;
  }

  async getCurrentCredentials() {
    return Auth.currentCredentials();
  }

  async signOut(): Promise<void> {
    return Auth.signOut().then((v) => {
      authSession$.next(new Session(null));
      return v;
    });
  }
}
