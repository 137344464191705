import { useEffect, useState } from "react";
import { Observable } from "rxjs/internal/Observable";
import { authSession$ } from "../../services";

export function useIdentity(initialState = false) {
  return useFromStream(authSession$, initialState);
}

export function useFromStream<T = any>(
  stream$: Observable<T>,
  defaultValue: any = null
): [T] | typeof defaultValue {
  const [data, setData]: [any, any] = useState(defaultValue);

  useEffect(() => {
    const subscription = stream$.subscribe(setData);
    return () => subscription.unsubscribe();
  }, []);

  return [data];
}
